<template>
  <div id="rmbCashSingle">
    <header-nav :content="`${$t('pages_router_021')} / ${ $t('pages_router_056')}`" />
    <div class="common-card-body">
      <div class="single-header" @click="$router.push({ path: '/funds/rmb/cash_batch', query: { accountNo: applyForm.accountNo}})">
        <swap-outlined class="ic-switch" />
        {{ $t('pages_pay_013') }}
      </div>
      <div style="display: flex; justify-content: center">
        <a-steps :current="1" size="small" class="single-apply-steps">
          <a-step :title="$t('pages_pay_014')" />
          <a-step :title="$t('pages_pay_015')" />
          <a-step :title="$t('pages_pay_016')" />
        </a-steps>
      </div>
      <div style="display: flex; justify-content: center">
        <a-form
          ref="applyRef"
          class="ant-round-form single-apply-form"
          layout="vertical"
          :model="applyForm"
          :rules="applyRules"
          @finish="applySubmit"
          @finishFailed="submitError"
        >
          <a-form-item name="payeeId">
            <template #label>
              <span>{{ $t('pages_pay_028') }}</span>
              <div class="area-add flex-align" @click="$router.push('/funds/inbounds/payee_edit')">
                <img src="~@/assets/icons/ic-user-add.png">
              </div>
            </template>
            <div class="person-info flex-align" @click="payeeChange">
              <img v-if="payeeInfo.code" class="img-add" src="~@/assets/icons/ic-user.png">
              <span v-else style="color: #bfbfbf">{{ $t('common_text_005') }}</span>
              <span style="margin-left: 12px" class="ellipsis">{{ payeeInfo.name }}</span>
              <div v-if="payeeInfo.code" style="margin-left: auto" class="flex-align">
                <img class="img-country" :src="getCountryLogo(payeeInfo.currency)">
                <span style="margin-left: 9px">{{ payeeInfo.currency }}</span>
                <span style="margin-left: 15px" class="ellipsis">{{ payeeInfo.branchName }}·{{ payeeInfo.bankNum }}</span>
                <up-outlined v-if="payeeSelected" class="ic-select" />
                <down-outlined v-else class="ic-select" />
              </div>
              <div v-show="payeeSelected" class="select-area" @click="e => e.stopPropagation()">
                <person-select
                  ref="payeeRef"
                  method-type="cash"
                  @select="payeeSelect"
                />
              </div>
            </div>
          </a-form-item>

          <a-form-item name="tradeType" :label="$t('pages_pay_030')">
            <a-select v-model:value="applyForm.tradeType" :placeholder="$t('common_text_005')">
              <a-select-option
                v-for="item in tradeTypeList"
                :key="item.code"
                :value="item.code"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item v-show="applyForm.tradeType" name="settlementType" :label="$t('pages_pay_036')">
            <div class="settle-list">
              <div class="settle-card active" style="width: 300px">
                <div class="title">{{ $t('pages_pay_352') }}</div>
                <div class="content">
                  <div>{{ $t('pages_pay_039') }}</div>
                  <div style="margin-top: 5px">{{ $t('pages_pay_353') }}</div>
                  <div v-if="feeStandard" style="margin-top: 8px">
                    <div>{{ $t('pages_pay_042') }}</div>
                    <div v-if="feeStandard.feeAmt && feeStandard.feeRate">{{ feeStandard.feeAmt + feeStandard.feeCurrency }}/{{ $t('pages_pay_295') }} + {{ $t('pages_pay_294') }}*{{ feeStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                    <div v-if="!feeStandard.feeAmt && feeStandard.feeRate">{{ $t('pages_pay_294') }}*{{ feeStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                    <div v-else>{{ feeStandard.feeAmt + feeStandard.feeCurrency }}/{{ $t('pages_pay_295') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </a-form-item>

          <a-form-item name="amount" style="width: 300px">
            <template #label>
              <div class="flex-align" style="width: 100%">
                <span>{{ $t('pages_pay_125') }}</span>
                <span v-if="availableAmount !== null" class="amount-text">
                  {{ $t('pages_pay_124') }}: {{ parseMoney(availableAmount) }}
                </span>
              </div>
            </template>
            <a-input
              v-model:value="applyForm.amount"
              size="large"
              style="padding-right: 50%"
              :placeholder="$t('common_text_006')"
              @change="amountChange"
            />
            <span
              v-if="availableAmount !== null"
              class="amount-text full-all"
              @click="fullAllMount"
            >{{ $t('pages_pay_035') }}</span>
            <a-form-item-rest>
              <a-select
                v-model:value="applyForm.remitCurrency"
                size="large"
                class="area-select"
                disabled
                :bordered="false"
              >
                <template #suffixIcon>
                  <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                </template>
                <a-select-option
                  v-for="item in cashCurList"
                  :key="item.currency"
                  :value="item.currency"
                >
                  <img :src="getCountryLogo(item.currency)" class="select-img-country">
                  <span>{{ item.currency }}</span>
                </a-select-option>
              </a-select>
            </a-form-item-rest>
          </a-form-item>

          <a-spin v-show="feeLoading" :tip="$t('pages_pay_050')" style="margin-top: 18px" />
          <div v-show="feeAmount !== null" class="fee-detail flex-align">
            <img src="~@/assets/icons/ic-money.png">
            <span>{{ $t('pages_pay_047') }}</span>
            <span style="margin-left: 5px">{{ parseMoney(feeAmount) }} {{ feeCurrency }}</span>
            <a-tag style="margin-left: 8px" color="pink">{{ feePattern === '00' ? $t('pages_pay_048') : $t('pages_pay_049') }}</a-tag>
          </div>

          <a-form-item name="merSingleNo" :label="$t('pages_pay_056')">
            <a-input v-model:value="applyForm.merSingleNo" :placeholder="$t('pages_pay_058')" allow-clear />
          </a-form-item>
          <a-form-item name="tradeContent" :label="$t('pages_pay_057')">
            <a-textarea v-model:value="applyForm.tradeContent" class="input-primary" :placeholder="$t('common_text_006')" allow-clear />
          </a-form-item>
          <a-form-item style="text-align: right">
            <a-button
              :loading="loading"
              html-type="submit"
              type="primary"
              shape="round"
              style="min-width: 138px; margin-top: 24px"
            >{{ $t('common_text_002') }}</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import PersonSelect from '@/components/person-select'
import { ref, reactive, toRefs, computed, watch, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { _pay } from '@/api'
import { getCountryLogo, parseMoney, getAttrStatusList } from '@/util'
import i18n from '@/locale'
export default {
  name: 'RmbAccount',
  components: {
    'header-nav': HeaderNav,
    'person-select': PersonSelect
  },
  setup () {
    let timer = null
    const validatePerson = () => {
      const { payeeId } = state.applyForm
      if (!payeeId) return Promise.reject(i18n.global.t('pages_pay_017'))
      return Promise.resolve()
    }
    const validatorAmount = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('pages_pay_019'))
      if (!(!isNaN(parseFloat(value)) && isFinite(value))) return Promise.reject(i18n.global.t('pages_pay_020'))
      const splitArea = String(value).split('.')
      if (splitArea[0].length > 16 || (splitArea[1] && splitArea[1].length > 2)) {
        return Promise.reject(i18n.global.t('pages_pay_022'))
      } else if (+value > state.availableAmount) {
        return Promise.reject(i18n.global.t('pages_pay_296'))
      }
      return Promise.resolve()
    }

    const applyRef = ref(null)
    const payeeRef = ref(null)

    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      loading: false,
      feeLoading: false,
      availableAmount: +route.query.balance,
      payeeInfo: {},
      payeeSelected: false,
      payees: [],
      feeStandard: null,
      cashCurList: [{ currency: 'CNY' }],
      businessType1: null,
      businessType2: null,
      feeAmount: null,
      feeCurrency: null,
      feePattern: '00',
      applyForm: {
        accountNo: route.query.accountNo,
        payeeId: null,
        tradeType: null,
        settlementType: '20',
        amount: null,
        amountMark: 'remit',
        remitCurrency: 'CNY',
        arrivalCurrency: 'CNY',
        rate: 1,
        arithmetic: 'DIV',
        merSingleNo: '',
        tradeContent: ''
      },
      applyRules: {
        payeeId: [{ required: true, validator: validatePerson, trigger: 'blur' }],
        tradeType: [{ required: true }],
        settlementType: [{ required: true }],
        amount: [{ required: true, validator: validatorAmount }],
        merSingleNo: [{ max: 20, message: i18n.global.t('common_text_025') }],
        tradeContent: [{ max: 256, message: i18n.global.t('common_text_026') }]
      }
    })

    state.tradeTypeList = computed(() => {
      if ((state.businessType1 && !state.businessType2)) return getAttrStatusList('tradeTradeCode')
      else if ((!state.businessType1 && state.businessType2)) return getAttrStatusList('serviceTradeCode')
      else return getAttrStatusList('tradeCode')
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    watch(() => state.applyForm.tradeType,
      () => {
        getFeeAmount()
        getReceiveStandard()
      }
    )

    const fullAllMount = () => {
      state.applyForm.amount = state.availableAmount
      amountChange()
    }

    const payeeChange = () => {
      state.payeeSelected = !state.payeeSelected
    }

    const payeeSelect = (selectId) => {
      state.businessType1 = null
      state.businessType2 = null
      state.applyForm.tradeType = null
      state.payeeInfo = state.payees.find(item => item.code === selectId)
      state.applyForm.payeeId = selectId
      const { businessType } = state.payeeInfo
      if (businessType.indexOf('00') !== -1) state.businessType1 = '00'
      if (businessType.indexOf('01') !== -1) state.businessType2 = '01'
      applyRef.value.validateFields('payeeId')
      payeeChange()
    }

    const amountChange = () => {
      clearInterval(timer)
      timer = setInterval(() => {
        clearInterval(timer)
        getFeeAmount()
      }, 300)
    }

    const getFeeAmount = async () => {
      const { tradeType, amount } = state.applyForm
      state.feeAmount = null
      state.feePattern = ''
      state.feeCurrency = ''
      if (tradeType && amount) {
        if ((!(!isNaN(parseFloat(amount)) && isFinite(amount)))) return
        state.feeLoading = true
        const query = {
          tradeType,
          tradeCurrency: 'CNY',
          tradeAmount: amount,
          settlementType: '00'
        }
        const res = await _pay.getFeeAmount(query)
        if (res.data.succ) {
          const { feeAmt, feePattern, feeCurrency } = res.data.data
          state.feeAmount = feeAmt
          state.feePattern = feePattern
          state.feeCurrency = feeCurrency
        } else {
          message.destroy()
          message.error(res.data.resMsg)
        }
        state.feeLoading = false
      }
    }

    const getReceiveStandard = async () => {
      state.feeStandard = null
      const res = await _pay.getFeeReceiveConfig({ tradeType: state.applyForm.tradeType })
      if (res.data.succ && res.data.data) {
        for (const item of res.data.data) {
          if (item.settlementType === '00') {
            state.feeStandard = item
            break
          }
        }
      }
    }

    const submitError = () => {
      message.error(i18n.global.t('common_text_030'))
    }

    const applySubmit = async () => {
      if (state.feeAmount === null) return message.error(i18n.global.t('pages_pay_059'))
      state.loading = true
      const data = Object.assign({}, state.applyForm, { payee: state.applyForm.payeeId })
      _pay.singleWithdrawApply(data)
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            router.push({ path: '/funds/apply_success', query: { key: 'inbound' }})
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => console.log(err))
    }

    const loadPayees = async () => {
      const res = await _pay.getPayeeList({ pageEnable: false, status: '01' })
      if (res.data.succ) {
        state.payees = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            code: String(item.id),
            name: item.payeeName
          })
        })
        payeeRef.value.initData(state.applyForm.payeeId, state.payees)
      }
    }

    const init = () => {
      loadPayees()
    }

    init()

    return {
      applyRef,
      payeeRef,
      getCountryLogo,
      parseMoney,
      payeeChange,
      payeeSelect,
      amountChange,
      fullAllMount,
      loadPayees,
      applySubmit,
      submitError,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../styles/apply-single.less';
</style>

